import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { routes } from './router'
Vue.use(VueRouter)
Vue.use(store)

const router = new VueRouter({
	mode: 'history',
	routes,

});
router.beforeEach((to, form, next) => {
	console.log(to.path, form)
	if (to.path == "/mycontent" || to.path == "/supply" || to.path == "/wanttobuy" || to.path == "/publishvideo" || to.path == "/empower") {
		if (store.getters.info && store.getters.info.access_token) {
			return next()
		} else {
			store.commit("SET_type", 2)
			return next({ name: "listView" })
		}
	} else {
		next()
	}
})
export default router
