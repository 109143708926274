<template>
  <div id="Login">
    <div class="Login_opacity">
      <div class="Login_center">
        <div class="Login_left">
          <el-image :src="require('@/assets/video/LCLKlogo.jpg')" fit="fill" />
        </div>
        <div class="Login_right">
          <el-image
            class="Login_close"
            :src="require('../assets/close.png')"
            fit="fill"
            @click="Clclose"
          />
          <el-image
            class="Login_title"
            :src="require('../assets/video/LClogo.jpg')"
            fit="fill"
          />
          <div class="Login_Type">
            <span @click="CK_LoginType(1)">
              <div :class="{ Login_text: formData.loginType == 1 }">
                密码登录
              </div>
              <div :class="{ Login_active: formData.loginType == 1 }"></div>
            </span>
            <span @click="CK_LoginType(2)">
              <div :class="{ Login_text: formData.loginType == 2 }">
                验证码登录
              </div>
              <div :class="{ Login_active: formData.loginType == 2 }"></div>
            </span>
          </div>
          <!-- 密码登录 -->
          <div class="Login_form">
            <div class="Login_form_item bgF5">
              <el-image
                class="PrefixIcon"
                :src="require('@/assets/PhoneIcon.png')"
                fit="cover"
              />
              <el-input
                v-model="formData.account"
                class="w-50 m-2"
                placeholder="手机号码"
              >
              </el-input>
            </div>
            <div class="Login_form_item bgF5" v-if="formData.loginType == 1">
              <el-image
                class="PrefixIcon"
                :src="require('@/assets/CodeIcon.png')"
                fit="cover"
              />
              <el-input
                v-model="formData.password"
                placeholder="输入你的密码"
                style="width: 84%"
                show-password
              >
              </el-input>
            </div>
            <div class="Login_form_item bgF5" v-if="formData.loginType == 2">
              <el-image
                class="PrefixIcon"
                :src="require('@/assets/CodeIcon.png')"
                fit="cover"
              />
              <el-input
                v-model="formData.codeValue"
                placeholder="输入你的验证码"
                style="width: 50%"
              >
              </el-input>
              <div
                class="Obtain"
                :class="[settime ? 'LoginText' : 'LoginSettime']"
                @click="slideCode"
              >
                {{ settime ? "获取验证码" : `${count}s` }}
              </div>
            </div>
            <div
              class="Forgot"
              v-if="formData.loginType == 1"
              @click="Forgot_fn()"
            >
              忘记密码?
            </div>
          </div>
          <div v-show="slideShow" class="slide_index">
            <div class="slide_relative">
              <slide-verify
                ref="slideblock"
                :l="42"
                :r="10"
                :w="350"
                :h="200"
                slider-text="向右滑动"
                @success="onSuccess"
                @fail="onFail"
                @refresh="onRefresh"
                class="slide"
              ></slide-verify>
            </div>
          </div>

          <el-button class="ElBtn" type="primary" size="medium" @click="ClLogin"
            >登录</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logon, GetCode } from "@/api/Login";
import { Message } from "element-ui";
export default {
  name: "LoginComponents",
  data() {
    return {
      input1: "",
      settime: true,
      count: 60,
      Interval: null,
      slideShow: false,
      formData: {
        account: "", //用户名
        password: null, //密码
        loginType: 1, //登陆状态
        codeKey: "", // 发送验证码接口返回的Key，
        codeValue: "", //验证码
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    CK_LoginType(type) {
      this.formData.loginType = type;
    },
    slideCode() {
      //点击获取验证码弹出滑块进行校验
      if (!this.settime) {
        return false;
      }
      if (this.formData.account.length == 11) {
        this.slideShow = true;
        this.$refs.slideblock.handleClick();
      } else {
        Message.error("请输入您的用户名");
      }
    },
    async ObtainCode() {
      let _this = this;
      if (this.settime == false) {
        return false;
      }
      if (this.formData.account.length != 11 && this.settime == true) {
        console.log(111);
        Message.error("用户名错误，请仔细检查");
        return false;
      }
      let res = await GetCode(this.formData);
      console.log(`output->res`, res);
      if (res.data.code == 200) {
        //获取成功
        Message.success("验证码发送成功");
        this.formData.codeKey = res.data.data;
        this.settime = true;
      } else {
        //获取失败
        Message.error("验证码发送失败");
        return false;
      }
      //发送验证码
      if (!this.settime) {
        console.log(11);
        return false;
      }
      this.settime = false;
      this.Interval = setInterval(function () {
        console.log("定时器执行");
        if (_this.count == 0) {
          clearTimeout(_this.Interval);
          _this.settime = true;
          _this.count = 60;
        } else {
          _this.count--;
        }
        console.log(_this.count);
      }, 1000);
    },
    async ClLogin() {
      // 登录
      // const loading = this.$loading({
      //   lock: true,
      //   text: "登陆中",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      try {
        if (this.formData.loginType == 1) {
          // 密码登录
          let res = await logon(this.formData);
          console.log("密码登录", res);
          if (res.data.data.avatar) {
            console.log("登陆");
            res.data.data.avatar = `https://sxtapp.oss-cn-hangzhou.aliyuncs.com/${res.data.data.avatar}`;
          }

          this.$store.commit("SET_INFO", res.data.data);
          this.Clclose();
          // loading.close();
        } else {
          //验证码登陆
          let res = await logon(this.formData);
          res.data.data.avatar = `https://sxtapp.oss-cn-hangzhou.aliyuncs.com/${res.data.data.avatar}`;
          this.$store.commit("SET_INFO", res.data.data);
          this.Clclose();
          console.log("验证码登陆=》", res);
          // loading.close();
        }
      } catch (error) {
        // loading.close();
        console.log(`outputerror`, error);
      }
    },
    onSuccess() {
      console.log("图形验证成功");
      this.slideShow = false;
      this.$refs.slideblock.reset();
      this.ObtainCode();
    },
    onFail() {
      console.log("图形验证失败");
    },
    onRefresh() {
      console.log("图形刷新");
    },
    Clclose() {
      //关闭登录弹框
      this.$store.commit("SET_LOGIN", false);
      this.$store.commit("SET_type", 2);
      this.$router.push({ path: "/" });
      window.location.reload();
    },
    Forgot_fn() {
      //忘记密码
      console.log("tiao");
      this.$store.commit("SET_LOGIN", false);
      this.$router.push({ path: "/forgot" });
    },
  },
  beforeDestroy() {
    clearTimeout(this.Interval);
  },
};
</script>

<style scoped lang="less">
#Login {
  font-family: PingFangSC-Regular, PingFang SC;
  .Login_opacity {
    background-color: #00000050;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Login_center {
    width: 700px;
    height: 430px;
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    .Login_left {
      width: 300px;
      height: 100%;
      // background-color: #2959fb;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 40px;
      > img {
        width: 150px;
        height: auto;
      }
    }
    .Login_right {
      padding: 40px;
      position: relative;
      // width:e("calc(60% - 40px)");
      width: calc(~"60% - 40px");
      display: flex;
      flex-direction: column;
      align-items: center;
      .Login_close {
        width: 18px;
        height: 18px;
        position: absolute;
        right: 32px;
        top: 32px;
      }
      .Login_title {
        // width: 120px;
        width: 174px;
      }
      .Login_Type {
        display: flex;
        justify-content: left;
        width: 100%;
        cursor: pointer;
        > span {
          font-size: 13px;
          margin: 20px 14px 0px 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      .Login_active {
        background-color: #2959fb;
        width: 15px;
        height: 3px;
        border-radius: 10px;
      }
      .Login_text {
        font-weight: 600;
      }
    }
    .PrefixIcon {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 15px;
    }
    .Login_form {
      width: 100%;
      height: 170px;
    }
    .Login_form_item {
      display: flex;
      align-items: center;
      border-radius: 5px;
      height: 46px;
      margin: 15px 0 10px 0;
      position: relative;
    }
    .bgF5 {
      background-color: #f5f5f5;
    }
    .Forgot {
      text-align: right;
      cursor: pointer;
      font-size: 12px;
      height: 20px;
    }
    /deep/ .el-input__inner {
      border: none !important;
      background: transparent;
      padding: 0;
    }
    .Obtain {
      width: 80px;
      border-left: 1px solid #acb3c8;
      padding-left: 3px;
      line-height: 18px;
      height: 18px;
      font-size: 13px;
      position: absolute;
      right: 0;
      color: #acb3c8;
      cursor: pointer;
    }
    .LoginSettime {
      width: 50px;
      text-align: center;
    }
    .LoginText {
    }
    .ElBtn {
      width: 100%;
      height: 44px;
      border-radius: 30px;
      font-size: 18px;
      font-weight: 400;
      background: #2959fb;
      box-shadow: 0px 5px 5px 0px rgba(89, 189, 150, 0.14);
      margin-top: 20px;
    }
    .slide {
      position: absolute;
      /* 通过设置下面两个属性，并不会达到垂直水平居中的效果，还需要进行位移 */
      top: 50%;
      left: 50%;
      /* 通过transform属性的translate 进行位移 */
      transform: translate(-50%, -50%); /*百分比是按照当前元素的宽高而定*/
    }
    .slide_index {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
    }
    .slide_relative {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
}
</style>
