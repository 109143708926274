import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/normalize.less';
import '@/utils/rem.js';
import MetaInfo from 'vue-meta-info'
import SlideVerify from 'vue-monoplasty-slide-verify';//图形验证

Vue.use(MetaInfo)

import {
	Radio,
	RadioGroup,
	Skeleton,
	SkeletonItem,
	Backtop,
	Dialog,
	Icon,
	Drawer,
	Pagination,
	Image,
	Notification,
	Input,
	Button,
	Form,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	Loading,
	Upload,
	// Message,
	scrollbar,
	select,
	option,
} from 'element-ui';
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Skeleton)
Vue.use(SkeletonItem)
Vue.use(Backtop)
Vue.use(Dialog)
Vue.use(Icon)
Vue.use(Drawer)
Vue.use(Pagination)
Vue.use(Image)
Vue.use(Input)
Vue.use(Button)
Vue.use(Form)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Loading)
Vue.use(SlideVerify);
Vue.use(Upload);
// Vue.use(Message);
Vue.use(scrollbar);
Vue.use(select);
Vue.use(option);

Vue.config.productionTip = false
Vue.prototype.$notify = Notification;

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')

router.afterEach(() => {
	document.getElementById('app').scrollTop = 0
})
