import axios from 'axios'
import store from '@/store/index';
import NProgress from 'nprogress';
import { Message } from "element-ui";
import 'nprogress/nprogress.css';

NProgress.configure({
	showSpinner: false
})
const service = axios.create({
	baseURL: process.env.NODE_ENV == 'development' ? '/api' : process.env
		.VUE_APP_BASE_API, // url = base url + request ur
	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 100000 // request timeout
})

// request interceptor
service.interceptors.request.use(
	config => {
		NProgress.start();
		if (store.state.info.access_token) {
			config.headers['Blade-Auth'] = store.state.info.access_token;
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	response => {
		NProgress.done()
		const status = response.data.code || response.status;
		// console.log(status !== 200 && status !== 304)
		if (status !== 200 && status !== 304) {
			Message({
				showClose: true,
				message: response.data.msg,
				type: 'error'
			})
			return Promise.reject(new Error(response.data.msg))
		}
		return response
	},
	error => {
		if (error.response.status === 509) {
			store.dispatch('toggleShow', true)
			store.dispatch('setUrl', error.response.data ? error.response.data.realRequestUri : '')
		}
		return Promise.reject(error)
	}
)

export default service
