import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import {
	isHasHttp
} from '@/utils/utils';
import {
	getList,
	getListSearch,
	getVideoList,//视频列表
} from '@/api/list'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		industryList: [],
		list: [],
		listSearch: [],
		loading: false,
		loadingSearch: true,
		type: 2,
		industryId: '',
		keyword: '',
		total: 0,
		show: false,
		refresh: false,
		currentPage: 1,
		token: "",
		info: [],
		loginup: false,
		ks_info: [],
	},
	getters: {
		industryList: state => state.industryList,
		list: state => state.list,
		loading: state => state.loading,
		type: state => state.type,
		industryId: state => state.industryId,
		listSearch: state => state.listSearch,
		keyword: state => state.keyword,
		loadingSearch: state => state.loadingSearch,
		total: state => state.total,
		show: state => state.show,
		refresh: state => state.refresh,
		url: state => state.url,
		currentPage: state => state.currentPage,
		token: state => state.token,
		info: state => state.info,
		loginup: state => state.loginup,
		ks_info: state => state.ks_info,
	},
	mutations: {
		SET_industryList(state, industryList) {
			state.industryList = industryList;
		},
		SET_list(state, list) {
			state.list = list;
		},
		SET_listSearch(state, listSearch) {
			state.listSearch = listSearch;
		},
		SET_loading(state, loading) {
			state.loading = loading;
		},
		SET_loadingSearch(state, loadingSearch) {
			state.loadingSearch = loadingSearch;
		},
		SET_type(state, type) {
			state.type = type;
		},
		SET_industryId(state, industryId) {
			state.industryId = industryId;
		},
		SET_keyword(state, keyword) {
			state.keyword = keyword;
		},
		SET_total(state, total) {
			state.total = total;
		},
		TOGGLE_SHOW: (state, value) => {
			state.show = value
		},

		TOGGLE_REFRESH: state => {
			state.refresh = !state.refresh
		},

		SET_URL: (state, url) => {
			state.url = url
		},
		SET_currentPage: (state, currentPage) => {
			state.currentPage = currentPage
		},
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		SET_INFO: (state, info) => {
			state.info = info
		},
		SET_LOGIN: (state, loginup) => {
			state.loginup = loginup
		},
		SET_KSINFO: (state, ksinfo) => {
			state.ks_info = ksinfo;
		}
	},
	actions: {
		getList_axios({
			state,
			commit
		},
			arg) {
			commit('SET_loading', true);
			console.log(state.type);
			if (state.type == 1 || state.type == 2) {
				return new Promise((resolve, reject) => {
					let data = {
						current: arg.current || undefined,
						industryId: state.industryId || undefined,
						size: 10,
						type: state.type || undefined,
						searchType: 1,
					}
					getList(data).then(respones => {
						let res = respones.data.data
						res.list.forEach(item => {
							if (item.userAvatar) {
								if (!isHasHttp(item.userAvatar)) {
									item.avatar =
										`${process.env.VUE_APP_IMGURL}/${item.userAvatar}`
								}
							}
							if (item.offerType == 2) {
								if (item.picPath) {
									if (!isHasHttp(item.picPath)) {
										item.imgOne =
											`${process.env.VUE_APP_IMGURL}/${item.picPath}`
									}
								}
							} else {
								if (item.imgs) {
									item.imgs = item.imgs.split(',');
									if (!isHasHttp(item.imgs[0])) {
										item.imgOne =
											`${process.env.VUE_APP_IMGURL}/${item.imgs[0]}`
									}
								}
							}
						})
						setTimeout(() => {
							commit('SET_loading', false);
						}, 500)
						commit('SET_list', res);
						resolve();
					}).catch(error => {
						reject(error)
					})
				})
			}
			else if (state.type == 3) {//短视频
				console.log(arg)
				return new Promise((resolve, reject) => {
					let data = {
						current: arg.current || undefined,
						size: 30,
						type: state.type || undefined,
						searchType: 1,
						describe: state.keyword || undefined,
					}
					getVideoList(data).then(respones => {
						let res = respones.data.data;
						console.log("视频列表", res)
						setTimeout(() => {
							commit('SET_loading', false);
						}, 500)
						commit('SET_list', res);
						resolve();
					}).catch(error => {
						reject(error)
					})
				})
			}

		},
		getListSearch_axios({
			state,
			commit
		}) {
			commit('SET_loadingSearch', true);
			return new Promise((resolve, reject) => {
				let data = {
					current: state.currentPage || undefined,
					industryId: state.industryId || undefined,
					keyword: state.keyword || undefined,
					size: 10,
					type: state.type || undefined,
				}
				getListSearch(data).then(respones => {
					let res = respones.data.data
					res.list.forEach(item => {
						if (item.userAvatar) {
							if (!isHasHttp(item.userAvatar)) {
								item.avatar =
									`${process.env.VUE_APP_IMGURL}/${item.userAvatar}`
							}
						}
						if (item.offerType == 2) {
							if (item.picPath) {
								if (!isHasHttp(item.picPath)) {
									item.imgOne =
										`${process.env.VUE_APP_IMGURL}/${item.picPath}`
								}
							}
						} else {
							if (item.imgs) {
								item.imgs = item.imgs.split(',');
								if (!isHasHttp(item.imgs[0])) {
									item.imgOne =
										`${process.env.VUE_APP_IMGURL}/${item.imgs[0]}`
								}
							}
						}
					})
					setTimeout(() => {
						commit('SET_loadingSearch', false);
					}, 500)
					commit('SET_listSearch', res);
					commit('SET_total', res.total);
					resolve();
				}).catch(error => {
					reject(error)
				})
			})
		},
		toggleShow({
			commit
		}, value) {
			commit('TOGGLE_SHOW', value)
		},

		toggleRefresh({
			commit
		}) {
			commit('TOGGLE_REFRESH')
		},

		setUrl({
			commit
		}, url) {
			commit('SET_URL', url)
		},
		Delete({ commit }) {
			commit("SET_industryList", []);
			commit("SET_list", []);
			commit("SET_listSearch", []);
			commit("SET_loading", true);
			commit("SET_loadingSearch", true);
			commit("SET_type", 2);
			commit("SET_industryId", '');
			commit("SET_keyword", '');
			commit("SET_total", 0);
			commit("TOGGLE_SHOW", false);
			commit("TOGGLE_REFRESH", false);
			commit("SET_currentPage", 1);
			commit("SET_TOKEN", '');
			commit("SET_INFO", []);
			commit("SET_LOGIN", false);
		}
	},
	modules: {},
	plugins: [createPersistedState()]
})
