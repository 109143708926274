<template>
  <div id="Header">
    <div class="nav">
      <i class="el-icon-s-unfold" @click="show"></i>
      <!-- :src="require('@/assets/logo.webp')" -->
      <img
        :src="require('@/assets/video/LClogo.jpg')"
        alt="LOGO"
        class="logo"
        @click="go_fn()"
      />
      <div class="search">
        <ul>
          <!-- <li :class="active == 2 ? 'active' : ''" @click="click_fnTow(2)">
            供应
          </li>
          <li :class="active == 1 ? 'active' : ''" @click="click_fnTow(1)">
            求购
          </li> -->
          <li
            style="margin-right: 500px"
            :class="active == 3 ? 'active' : ''"
            @click="click_fnTow(3)"
          >
            短视频
          </li>
        </ul>
        <!-- <div class="input">
          <input
            type="text"
            v-model="value"
            placeholder="请输入关键字找帖子"
            @keydown.enter="search"
            @blur="blur_search"
          />
          <i class="icon" @click="search"></i>
        </div> -->
        <div class="Login">
          <div v-if="!LoginUP" class="Login-cl" @click="Login_fn()">登录</div>
          <!-- <el-image  class="Login-avatar" :src="src" /> -->
          <el-dropdown v-else :hide-on-click="true" @command="handleCommand">
            <span class="el-dropdown-link">
              <el-image class="Login-avatar" :src="src">
                <template #error>
                  <div>
                    <el-image
                      src="https://sxtapp.oss-cn-hangzhou.aliyuncs.com/app/avatar.png"
                      fit="cover"
                    />
                  </div>
                </template>
              </el-image>
            </span>
            <el-dropdown-menu class="menu" slot="dropdown">
              <!-- <el-dropdown-item class="menu_item" command="a"
                >我的内容</el-dropdown-item
              >
              <el-dropdown-item class="menu_item" command="b"
                >发布供应</el-dropdown-item
              >
              <el-dropdown-item class="menu_item" command="c"
                >发布求购</el-dropdown-item
              > -->
              <el-dropdown-item class="menu_item" command="d"
                >发布短视频</el-dropdown-item
              >
              <el-dropdown-item class="menu_item" command="e"
                >授权</el-dropdown-item
              >
              <el-dropdown-item class="menu_item" command="f" divided
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Delete } from "@/api/Login";
// import { getVideoList } from "@/api/list"
export default {
  name: "HeaderComponents",
  data() {
    return {
      value: "",
      // active: 2,
      src: this.$store.state.info.avatar,
      LoginUP: this.$store.state.info.access_token,
    };
  },
  computed: {
    keyword() {
      return this.$store.getters.keyword;
    },
    active: {
      get() {
        return this.$store.getters.type;
      },
      set(val) {
        this.$store.commit("SET_type", val);
      },
    },
  },
  watch: {
    active: {
      handler(nv) {
        console.log("间听到变化", nv);
        this.$store.commit("SET_type", nv);
        setTimeout(() => {
          if (this.$route.path == "/search" || this.$route.path == "/search/") {
            this.$store.commit("SET_currentPage", 1);
            this.$store.dispatch("getListSearch_axios", {
              type: nv.val,
            });
          } else {
            this.$store.dispatch("getList_axios", {
              current: 1,
              type: nv.val,
            });
          }
        }, 500);
      },
    },
  },
  created() {
    if (this.keyword) {
      this.value = this.keyword;
    }
    // this.active = this.$store.getters.type;
  },
  methods: {
    search() {
      if (this.value) {
        if (this.active == 3) {
          console.log(`output->搜索视频`);
          this.$store.commit("SET_keyword", this.value);
          this.$store.dispatch("getList_axios", { current: 1, type: 3 });
          // this.$router.push({path:"/video"});
        } else if (this.active == 4) {
          this.$store.commit("SET_keyword", this.value);
          this.$store.commit("SET_type", 2);
          if (this.$route.path != "/search" && this.$route.path != "/search/") {
            this.$router.push(
              {
                path: "/search",
              },
              () => {}
            );
          } else {
            if (this.$store.getters.type == 4) {
              this.$store.commit("SET_type", 1);
              console.log("执行完毕");
            }
            this.$store.commit("SET_currentPage", 1);

            this.$store.dispatch("getListSearch_axios", 1);
          }
        } else {
          this.$store.commit("SET_keyword", this.value);
          if (this.$route.path != "/search" && this.$route.path != "/search/") {
            this.$router.push(
              {
                path: "/search",
              },
              () => {}
            );
          } else {
            if (this.$store.getters.type == 4) {
              this.$store.commit("SET_type", 1);
              console.log("执行完毕");
            }
            this.$store.commit("SET_currentPage", 1);

            this.$store.dispatch("getListSearch_axios", 1);
          }
        }
      } else {
        this.$store.commit("SET_keyword", "");
        this.$router.push(
          {
            path: "/",
          },
          () => {}
        );
      }
    },
    blur_search() {
      if (this.value == "") {
        this.$store.commit("SET_keyword", "");
        this.$store.commit("SET_type", 2);

        this.$router.push(
          {
            path: "/",
          },
          () => {}
        );
      }
    },
    show() {
      this.$parent.show();
    },
    // click_fn(val) {
    //   // this.active = val;
    //   this.$store.commit("SET_type",val)

    //   if (this.$route.path == "/detail" || this.$route.path == "/detail/") {
    //     this.$router.push(
    //       {
    //         path: "/",
    //       },
    //       () => {}
    //     );
    //   } else {
    //     this.$router.push(
    //       {
    //         path: "/",
    //       },
    //       () => {}
    //     );
    //   }
    // },
    click_fnTow(val) {
      this.active = val;
      if (val == 3) {
        this.$store.commit("SET_type", val);
        this.$router.push({ path: "/video" });
      } else if (val == 2) {
        this.$store.commit("SET_type", val);
        this.$router.push({ path: "/" });
      } else if (val == 1) {
        this.$store.commit("SET_type", val);
        this.$router.push({ path: "/buying" });
      }
    },
    go_fn() {
      this.$router.push(
        {
          path: "/",
        },
        () => {}
      );
    },
    async handleCommand(command) {
      //下拉菜单
      this.active = 4;
      console.log(command);
      if (command == "a") {
        this.$router.push({ path: "/mycontent" });
        //我的内容
      } else if (command == "b") {
        //发布供应
        this.$router.push({ path: "/supply" });
      } else if (command == "c") {
        //发布求购
        this.$router.push({ path: "/wanttobuy" });
      } else if (command == "d") {
        //发布短视频
        this.$router.push({ path: "/publishvideo" });
      } else if (command == "e") {
        //授权
        this.$router.push({ path: "/empower" });
      } else if (command == "f") {
        //退出功能
        try {
          let res = await Delete();
          console.log(`output->res`, res);
          this.$store.dispatch("Delete");
          this.$router.push({ path: "/" });
          setTimeout(() => {
            window.location.reload();
          }, 500);
          //想清空vuex中的数据，让页面重新加载就行了
        } catch (error) {
          this.$store.dispatch("Delete");
          this.$router.push({ path: "/" });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      }
    },

    Login_fn() {
      // 登陆框弹出
      console.log(`output-Login`, "登陆点击");
      this.$store.commit("SET_LOGIN", true);
    },
  },
};
</script>

<style scoped lang="less">
#Header {
  background: #ffffff;

  font-family: PingFangSC-Regular, PingFang SC;
  .el-icon-s-unfold {
    font-size: 40px;
    margin-right: 20px;
    display: none;
  }

  .logo {
    height: 60px;
    flex-shrink: 0;
    margin-right: 20px;
  }

  .nav {
    max-width: 1200px;
    padding: 0 20px;
    margin: 0 auto;
    height: 79px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .search {
    display: flex;
    align-items: center;
    flex-shrink: 1;

    .input {
      width: 270px;
      height: 30px;
      border-radius: 15px;
      border: 1px solid #2959fb;
      overflow: hidden;
      display: flex;
      align-items: center;
      padding: 0 22px;
      margin-left: 24px;
      flex-shrink: 1;
      margin-right: 40px;

      input {
        width: 100%;
        flex-shrink: 1;
      }

      .icon {
        display: inline-block;
        width: 15px;
        height: 15px;
        background: url("../assets/ss.webp") no-repeat center;
        background-size: cover;
        margin-left: 22px;
      }
    }

    ul {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      li {
        width: 86px;
        height: 80px;
        font-size: 16px;
        font-weight: 500;
        color: #272a40;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.25s;
      }

      li:hover {
        cursor: pointer;
      }

      li.active {
        color: #ffffff;
        background: #2959fb;
      }
    }
  }
  .Login {
    display: flex;
    align-items: center;
    .Login-avatar {
      border-radius: 50%;
      width: 26px;
      height: 26px;
    }
    .Login-cl {
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      color: #32344a;
      cursor: pointer;
    }
  }
}
.menu {
  // padding: 20px;
  // padding-right: 70px;
  border-radius: 12px;
  text-align: left;
  font-weight: 600;
  color: block;
  overflow: hidden;
  .menu_item {
    // padding-right: 70px;
    width: 150px;
    padding: 0px 10;
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
@media screen and (max-width: 900px) {
  #Header {
    .el-icon-s-unfold {
      display: block;
    }
  }
}
</style>
