<template>
	<el-dialog :visible="show" title="操作频繁，请输入验证码" center :close-on-click-modal="false" width="400px" append-to-body
		top="30vh" custom-class="validate-dialog" @close="handleClose">

		<div class="image-wrap">
			<img :src="imageUrl" alt="" srcset="">
			<el-button type="primary" icon="el-icon-refresh" size="mini" :disabled="imageDisabled"
				@click="refreshValidateImage">刷新</el-button>
		</div>

		<el-form @submit.prevent>
			<el-input v-model="result" placeholder="请输入验证码">
				<el-button slot="append" type="primary" :disabled="disabled" @click="handleValidate">确认</el-button>
			</el-input>
		</el-form>
	</el-dialog>
</template>


<script>
	import {
		getValidateImage,
		validateCode
	} from '@/api/validate'
	import { Message } from 'element-ui';
	const baseUrl = process.env.VUE_APP_BASE_API;

	export default {
		computed: {
			show() {
				return this.$store.getters.show
			},

			url() {
				return this.$store.getters.url
			}
		},

		data() {
			return {
				imageUrl: '',
				disabled: false,
				verifyId: 'verifyId_value',
				form: {
					Authorization: '',
					BladeAuth: '',
					baseUrl: '',
					verifyId: '',
					realRequestUri: 'realRequestUri_value',
					result: ''
				},
				result: '',
				imageDisabled: false
			}
		},

		watch: {
			show: {
				immediate: true,
				async handler(val) {
					if (val) {
						this.verifyId = 'verifyId_value'
						this.imageUrl = ''

						const res = await getValidateImage(this.verifyId)
						if (res.data) {
							const {
								verifyId,
								verifyImgStr
							} = res.data
							this.verifyId = verifyId
							this.imageUrl = verifyImgStr
							this.form.verifyId = verifyId
							const headers = res.config.headers
							this.form.Authorization = headers.Authorization
							this.form.BladeAuth = headers['Blade-Auth']
							this.form.baseUrl = `${baseUrl}/api/blade-crm-es`
							this.form.realRequestUri = this.url
						}
					}
				}
			}
		},

		methods: {
			handleClose() {
				this.$store.dispatch('setUrl', '')
				this.$store.dispatch('toggleShow', false)
				Object.assign(this.$data, this.$options.data())
			},

			async refreshValidateImage() {
				this.imageDisabled = true

				try {
					const res = await getValidateImage(this.verifyId)
					if (res.data) {
						const {
							verifyId,
							verifyImgStr
						} = res.data
						this.verifyId = verifyId
						this.imageUrl = verifyImgStr
						this.form.verifyId = verifyId
						const headers = res.config.headers
						this.form.Authorization = headers.Authorization
						this.form.BladeAuth = headers['Blade-Auth']
						this.form.baseUrl = `${baseUrl}/api/blade-crm-es`
						this.form.realRequestUri = this.url
					}
					this.imageDisabled = false
				} catch {
					this.imageDisabled = false
				}
			},

			async handleValidate() {
				if (!this.result) {
					Message.error('请填写验证码')
					return
				} else {
					this.form.result = this.result
				}

				this.disabled = true

				try {
					const form = new FormData()

					for (const key in this.form) {
						form.append(key, this.form[key])
					}
					const res = await validateCode(form)
					if (res.data && !res.data.result) {
						Message.error('验证码错误，请重新填写')
						this.refreshValidateImage()
					} else {
						this.handleClose()
						this.$store.dispatch('toggleRefresh')
					}
					this.disabled = false
				} catch {
					this.disabled = false
				}
			}
		}
	}
</script>

<style scoped>
	.image-wrap {
		display: flex;
		margin-bottom: 30px;
		justify-content: space-between;
	}

	.image-wrap>img {
		display: block;
		overflow: hidden;
	}
</style>

<style>
	.validate-dialog>.el-dialog__body {
		padding: 30px 20px;
	}

	.validate-dialog>.el-input__inner {
		height: 48px !important;
		line-height: 48px !important;
	}
</style>
