<template>
  <div id="Footer">
    <div class="nav">
      <div class="left">
        <!-- <img src="@/assets/logo(1).webp" alt="" /> -->
        <img
          style="width: 134px; height: auto"
          src="@/assets/video/LClogo.jpg"
          alt=""
        />

        <!-- <div>联系我们</div>
				<div>欢迎垂询：400-000-8827</div>
				<div>总部地址：浙江省杭州市滨江区天恒大厦22F</div> -->
      </div>
      <div class="right">
        <!-- <div>
          <img src="@/assets/gzh.webp" alt="" />
          关注公众号
        </div>
        <div>
          <img src="@/assets/sxt.webp" alt="" />
          下载app
        </div> -->
      </div>
    </div>
    <div class="nav2">
      <!-- <ul>
        <li>友情链接：</li>
        <li>
          <a href="http://www.hxsx.cn/" target="_blank">商讯通官网</a>
        </li>
        <li>
          <a href="https://www.hc360.com/" target="_blank">慧聪网</a>
        </li>
        <li>
          <a href="http://www.11467.com/" target="_blank">顺企网</a>
        </li>
        <li>
          <a href="http://china.makepolo.com/" target="_blank">马可波罗</a>
        </li>
      </ul> -->
      <div>
        Copyright © 2022 - 2023 hxsx.com All rights reserved
        华夏商讯科技有限公司 版权所有
      </div>
      <div>浙ICP备2022008373号</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponents",
  data() {
    return {};
  },
};
</script>

<style scoped lang="less">
#Footer {
  background: #ffffff;
  padding: 20px 20px 37px;

  .nav2 {
    margin: 15px auto;
    max-width: 1200px;

    div {
      margin: 10px auto;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }

    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li {
        font-size: 14px;
        font-weight: 400;
        color: #1b1b1b;
        margin-right: 34px;
      }

      li:not(:first-child):hover {
        cursor: pointer;
      }

      li:first-child {
        font-weight: bold;
        color: #1b1b1b;
      }
    }
  }

  .nav {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    // border-bottom: 1px solid #f2f7fa;

    .right {
      display: flex;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      img {
        width: 113px;
      }
    }

    .left {
      div {
        font-size: 14px;
        font-weight: 400;
        color: #1b1b1b;
        margin-bottom: 16px;
      }

      img {
        height: 42px;
      }

      div:nth-child(2) {
        font-size: 16px;
        font-weight: bold;
        color: #1b1b1b;
        margin: 31px 0 16px;
      }
    }
  }
}
</style>
