export const routes = [{
	path: '/',
	name: 'listView',
	component: () => import('../views/video/index.vue')
	// component: () => import('../views/list/index.vue')
},
{
	path: "/buying",
	name: "buyingView",
	component: () => import('../views/Buying/index.vue')
},
{
	path: '/detail',
	name: 'detailView',
	component: () => import('../views/detail/index.vue')
},
{
	path: '/search',
	name: 'searchView',
	component: () => import('../views/search/index.vue')
}, {
	path: '/empower',
	name: 'empowerView',
	component: () => import('../views/empower/index.vue')
}, {
	path: '/forgot',
	name: 'ForgotView',
	component: () => import('../views/Forgot/index.vue')
}, {
	path: '/supply',
	name: 'supplyView',
	component: () => import('../views/supply/index.vue')
}, {
	path: '/publishvideo',
	name: 'PublishVideo',
	component: () => import('../views/PublishVideo/index.vue')
},
{
	path: '/wanttobuy',
	name: 'wanttobuyView',
	component: () => import('../views/wanttobuy/index.vue')
},
{
	path: '/mycontent',
	name: 'MycontentView',
	component: () => import('../views/MyContent/index.vue')
}, {
	path: '/video',
	name: 'videoView',
	component: () => import('../views/video/index.vue')
},

]

