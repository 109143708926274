import request from '@/utils/axios';

//行业列表
export function getTopLevelIndustry(data) {
	return request({
		url: "/blade-sxt-b2b/web/industry/getTopLevelIndustry",
		method: "post",
		data
	})
}

// 列表
export function getList(data) {
	return request({
		url: "/blade-sxt-b2b/web/inquiryOffer/getList",
		method: "post",
		data
	})
}

// 详情
export function getDetail(data) {
	return request({
		url: "/blade-sxt-b2b/web/inquiryOffer/getDetail",
		method: "post",
		data
	})
}

// 搜索页列表
export function getListSearch(data) {
	return request({
		url: "/blade-sxt-b2b/web/inquiryOffer/getListSearch",
		method: "post",
		data
	})
}
export function getVideoList(data) {
	return request({
		url:"blade-sxt-b2b/web/video/getVideoList",
		method:"post",
		data
	})
}
