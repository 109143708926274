<template>
	<div id="Drawer">
		<el-drawer title="行业类型" :visible.sync="drawer" direction="ltr">
			<el-radio-group v-model="radio" @input="change_fn">
				<el-radio label="">全部行业</el-radio>
				<el-radio :label="item.id" v-for="item in industryList" :key="item.id">{{item.industry}}</el-radio>
			</el-radio-group>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		name: 'DrawerComponents',
		data() {
			return {
				drawer: false,
				radio: ''
			}
		},
		computed: {
			industryList() {
				return this.$store.getters.industryList
			},
			industryId() {
				return this.$store.getters.industryId
			},
		},
		created() {
			if (this.industryId) {
				this.radio = this.industryId
			}
		},
		methods: {
			show() {
				this.drawer = true
			},
			change_fn(val) {
				this.$store.commit('SET_industryId', val)
				this.drawer = false;
				if (this.$route.path == '/detail') {
					this.$router.push({
						path: '/'
					})
					this.$store.dispatch('getList_axios', {
						current: 1
					})
				} else if (this.$route.path == '/search') {
					this.$store.dispatch('getListSearch_axios', {
						current: 1
					})
				} else {
					this.$store.dispatch('getList_axios', {
						current: 1
					})
				}
			}
		}
	}
</script>

<style scoped lang="less">
	#Drawer {
		background: #FFFFFF;
		border-radius: 10px;
		overflow: hidden;
		padding: 0 16px 34px;

		/deep/.el-drawer__header>:first-child::before {
			content: '';
			display: inline-block;
			width: 3px;
			height: 14px;
			background: #3966FD;
			margin-right: 10px;
		}


		/deep/.el-drawer__header>:first-child {
			font-size: 16px;
			font-weight: 600;
			color: #333333;
			letter-spacing: 1px;
			margin: 21px 0 24px;
			display: flex;
			align-items: center;
		}

		.el-radio-group {
			width: 100%;
		}

		/deep/.el-radio__input {
			display: none;

		}

		/deep/.el-radio__label {
			padding: 0;
		}

		/deep/.el-radio {
			width: 50%;
			margin: 11px 0;
			text-align: center;
			font-size: 16px;
			font-weight: 500;
			letter-spacing: 1px;
		}

		/deep/.el-radio__input.is-checked+.el-radio__label {
			color: #3966FD !important;
		}
	}
</style>
