import axios from "@/utils/axios";

export function logon(data){ //手机号 密码登录
    return axios({
        url:"/blade-sxt-b2b/web/b2bUser/login",
        method:"post",
        data:data,
    })
}
export function GetCode(data){ //获取验证码
   return  axios({
    url:"/blade-sxt-b2b/web/b2bUser/sendCode",
    method:"post",
    data:data,
   })
}
export function Delete(data){
    return  axios({
     url:"/blade-sxt-b2b/web/b2bUser/loginOut",
     method:"post",
     data:data,
    })
 }
 export function Uppassword(data){ //更新密码
    return  axios({
     url:"/blade-sxt-b2b/web/b2bUser/putPwd",
     method:"post",
     data:data,
    })
 }
 export function cancelPlateForm(data) { //取消授权
    return axios({
        url:"/blade-sxt-b2b/web/video/cancelPlatForm",
        method:"post",
        data
    })
 }
 export function queryAccessPlatForm() { //查询第三方平台授权状态
    return axios ({
        url:"/blade-sxt-b2b/web/video/queryAccessPlatForm",
        method:"get",
        
    })

 }
 export function accessPlatForm(data) {
    return axios ({
        url:"/blade-sxt-b2b/web/video/accessPlatForm",
        method:"post",
        data
    })
 }
 export function supplyOrPurchase(data) {//发布供应与求购
    return axios({
        url:"/blade-sxt-b2b/web/inquiryOffer/supplyOrPurchase",
        method:"post",
        data
    })
 }
 export function submitVideo(data) {//发布短视频
    return axios({
        url:"/blade-sxt-b2b/web/video/submitVideo",
        method:"post",
        data
    })
 }