<template>
  <div id="app" class="app" ref="app">
    <Header></Header>
    <router-view />
    <Footer></Footer>
    <Drawer ref="drawer"></Drawer>
    <el-backtop target=".app"></el-backtop>
    <ValidateDialog></ValidateDialog>
    <Login v-if="loginup"></Login>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Drawer from "@/components/Drawer";
import ValidateDialog from "@/components/ValidateDialog";
import Login from "@/components/Login.vue";
import { getTopLevelIndustry } from "@/api/list";
import { mapState } from "vuex";
export default {
  name: "App",
  metaInfo: {
    title: "最美聊城",
    meta: [
      {
        name: "keyWords",
        content: "在线营销、营销、精准、小微企业、采集、数字化、商讯通",
      },
      {
        name: "description",
        content:
          "商讯通集数据挖掘、内容整合、资源对接、传播引流、在线营销为一体的互联网大数据营销系统，通过全精准采集、地图采集、全网客源采集、短信群发等功能，为中小微企业提供精准客源，帮助企业实现数字化智能营销。",
      },
    ],
  },

  data() {
    return {};
  },
  components: {
    Header,
    Footer,
    Drawer,
    ValidateDialog,
    Login,
  },
  created() {
    this.getTopLevelIndustry_axios();
  },
  computed: {
    ...mapState(["loginup"]),
  },
  methods: {
    show() {
      this.$refs.drawer.show();
    },
    getTopLevelIndustry_axios() {
      getTopLevelIndustry().then((respones) => {
        this.$store.commit("SET_industryList", respones.data.data);
      });
    },
  },
};
</script>
<style lang="less">
.app {
  height: 100vh;
  overflow-x: hidden;
  // overflow-y: scroll;
}

.el-backtop {
  background: url("assets/置顶 top.webp") no-repeat center;
  background-size: cover;
  right: 198px;
  bottom: 200px;

  i {
    display: none;
  }
}

#nprogress .bar {
  background: #2959fb !important; //自定义颜色
}
</style>
