import request from '@/utils/axios';


// 获取验证码图片
export function getValidateImage(verifyId = 'verifyId_value') {
	return request({
		url: `/blade-sxt-b2b/kk-anti-reptile/refresh?verifyId=${verifyId}`,
		method: 'post'
	})
}

// 验证
export function validateCode(data) {
	return request({
		url: '/blade-sxt-b2b/kk-anti-reptile/validate',
		method: 'post',
		data
	})
}
// 图片视频file文件流上传
export function validateFile(data) {
	return request({
		url:"/blade-sxt-b2b/web/video/uploadFile",
		method:"post",
		data
	})
}
